import React from 'react';
import Container from '../../atoms/Container/Container';
import Img from "gatsby-image"
import FancyHeading from '../../molecules/FancyHeading/FancyHeading';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import SocialMediaList from '../../organisms/SocialMediaList/SocialMediaList';
import PortableText from '../../organisms/PortableText/PortableText';

const aboutSection = ({ block, raw }) => {
  const { primary, image, secondaryImage, heading, linkSections } = block;
  console.log(raw.text2);
  return (

    <div>
       <div className="relative flex flex-nowrap mt-10">
        {primary ?
          <div className="hidden xl:block absolute left-0 top-0 w-1/2 h-full px-12 ml-16" >
            <Img 
              className="h-full" 
              fluid={image.asset.fluid} 
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          :
          <div className="hidden xl:block absolute left-0 top-0 h-full w-1/2">
            <Img 
              className="h-full" 
              fluid={image.asset.fluid}
              imgStyle={{
                objectPosition: "top",
              }}
            />
            <div className="absolute left-0 top-0 h-full w-full bg-brand-red opacity-70"></div>
            <div className="absolute right-0 bottom-0 h-3/4 w-1/2 bg-brand-orange opacity-70"></div>
            <div className="absolute right-0 bottom-0 h-3/4 w-1/2 p-4">
              <Img 
                className="h-full" 
                fluid={secondaryImage.asset.fluid}
                imgStyle={{
                  objectPosition: "top",
                }}
              />
            </div>
          </div>
        }
        <div className="hidden xl:inline-flex w-1/2 h-full"></div>
        <div className="w-full xl:w-4/12 pl-11 pr-11 2xl:pr-0 pb-11">
          <FancyHeading center="" darkBg="">{heading}</FancyHeading>
          <PortableText blocks={raw.richText} />
          <div className="pb-6">
            {linkSections.map((links) => (
              <div className="flex flex-wrap">
                <ButtonGroup darkBg="" primary="" buttons={links.buttonGroup}/>
              </div>
            ))}
          </div>
          <SocialMediaList primary="true"/>
        </div>
      </div>
      {primary ?
        <></>
        :
        <Container>
          <div className="grid grid-rows-1 grid-cols-3 gap-x-10 h-40">
            <div className="h-full border-2 border-brand-lighter-grey"></div>
            <div className="h-full border-2 border-brand-lighter-grey"></div>
            <div className="h-full border-2 border-brand-lighter-grey"></div>
          </div>
        </Container>
      }
    </div>
  );
}

export default aboutSection;