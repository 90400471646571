import React from 'react';
import Container from '../../atoms/Container/Container';
import FancyHeading from '../../molecules/FancyHeading/FancyHeading';
import ReviewCarousel from '../../organisms/ReviewCarousel/ReviewCarousel';

const reviewsSection = ({ block }) => {
    const { heading, text } = block;
    return (
        <Container className="bg-brand-light-grey">
            <FancyHeading center="true" darkBg="">{heading}</FancyHeading>
            {text ? 
               <p className="font-lato w-3/5 mx-auto text-center text-brand-dark-grey text-lg mb-10">{text}</p>
              :
              <></>
            }
            <ReviewCarousel/>
        </Container>
    );
}

export default reviewsSection;