import { Link } from 'gatsby';
import React from 'react';
import Container from '../../../atoms/Container/Container';

//  

const videoSection = ({ title, caption, href, imageUrl, useOrderButton }) => (
  <Container>
    <div style={{position: "relative", width: "100%", height: "0", paddingBottom: "52.25%"}}>
      <iframe
        style={{ position: "absolute", width: "100%", height: "100%", left: "0", top: "0",}}
        src={href}
        srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${href}?autoplay=1><img src=${imageUrl} alt='${title}'><span>▶</span></a>`}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        title={title}
      ></iframe>
    </div>
    <div className="font-lato text-center mt-8 text-lg font-light">{caption}</div>

    {useOrderButton ? 
      <div className="flex justify-center mt-8">
        <Link
          to="/stockists"
          className="mb-4 mr-4 inline-flex items-center text-md font-lato font-bold leading-3 px-12 py-6 border-2 border-black uppercase bg-transparent transition transition-duration-500 hover:bg-brand-red hover:border-brand-red hover:text-white"
        >
          Pre-order Now
        </Link>
      </div>
      :
      <></>
    }
  </Container>
);

export default videoSection;