import React from 'react';
import Container from '../../atoms/Container/Container';
import FancyHeading from '../../molecules/FancyHeading/FancyHeading';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

const mentoringSection = ({ block }) => {
  const { heading, text, links } = block;
  return (
    <Container className="bg-brand-red text-white">
      <div className="items-center">
        <FancyHeading center={true} darkBg={true}>{heading}</FancyHeading>
        <p className=" text-white text-lg w-3/5 mx-auto text-center">{text}</p>
        {links ? 
          <div className="w-4/6 mx-auto flex justify-center mt-10">
            <ButtonGroup darkBg={true} primary="" buttons={links.buttonGroup}/>
          </div>
          :
          <></>
        }
        
      </div>
    </Container>
  );
}

export default mentoringSection;