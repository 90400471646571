import React from 'react';
import Container from '../../atoms/Container/Container';
import Img from "gatsby-image"
import FancyHeading from '../../molecules/FancyHeading/FancyHeading';
import PortableText from '../../organisms/PortableText/PortableText';
import { BsArrowLeftShort } from "@react-icons/all-files/bs/BsArrowLeftShort";
import Link from '../../atoms/Link/Link';
import Button from '../../molecules/Button/Button';

const authorSection = ({ block, raw }) => {
  const { primary, image, heading, file, link, externalLink } = block;
  
  return (
    <Container className={primary ? "bg-brand-red text-white" : "bg-white text-black"}>
      {image ? 
        <Img 
          className="float-none lg:float-right w-full lg:w-96 h-72  bg-brand-light-grey mb-8 lg:ml-8" 
          fluid={image.asset.fluid} 
        />
        : 
        <></>
      }
      <div className="" />
      {heading ? 
        <FancyHeading center={false} darkBg={primary}>{heading}</FancyHeading>
        :
        <></>

      }
      <PortableText blocks={raw.text} />
      {file && file.file ?
        <div className="mt-12">
          <Button internal={false} textOnDarkBg={primary} href={file.file.asset.url} target="blank">{file.buttonLabel}</Button>
        </div>
        :
        <></>
      }
      {link && link.buttonLabel && link.uri ? 
        <div className="mt-14">
          <Link className="font-lato text-xl flex items-center" to={link.uri}><BsArrowLeftShort className="inline-block mr-1 h-6 w-6" /><span>{link.buttonLabel}</span></Link>
        </div>
        :
        <></> 
      }
      {externalLink && externalLink.buttonLabel && externalLink.href ? 
        <div className="mt-14">
          <Button internal={false} textOnDarkBg={primary} href={externalLink.href} target="blank">{externalLink.buttonLabel}</Button>
        </div>
        :
        <></>
      }
    </Container>
  );
}

export default authorSection;