import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Slider from 'react-slick';
import Review from '../Review/Review';

function ReviewGroup() {
    
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
  };

  const data = useStaticQuery(
    graphql
    `
    query {
      featuredReviews: allSanityReviewOrderAndFeatured {
        nodes {
          reviews: featuredReviews {
            callOutText
            _rawSummary
            reviewerName
            reviewerTitle
          }
        }
      }
    }
    `
  );

  return(
    <Slider {...settings}>
      {data.featuredReviews.nodes[0].reviews.map((review) => (
        <Review review={review} primary={true} />
      ))}
    </Slider>
  );
}

export default ReviewGroup;