import React from 'react';
import Container from '../../atoms/Container/Container';
import Link from '../../atoms/Link/Link';
import FancyHeading from '../../molecules/FancyHeading/FancyHeading';
import NewsList from '../../organisms/NewsList/NewsList';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";

const newsSection = ({ block }) => {

    const { heading, link, links } = block;
    const buttons = links.buttonGroup;
    
    return (
      <Container className="bg-brand-light-grey">
          <div className="items-center">
            <FancyHeading center="true" darkBg="">{heading}</FancyHeading>
            {link ? 
              <div className="flex justify-center mb-14">
                <Link className="font-lato italic text-lg leading-5 underline" to={link.uri}><span>{link.buttonLabel}</span><BsArrowRightShort className="inline-block ml-1 h-6 w-6" /></Link>
              </div>
              :
              <></>
            }
            <NewsList />
            <div className="flex justify-center">
              <ButtonGroup darkBg="" primary="" buttons={buttons}/>
            </div>
          </div>
      </Container>
    );
}

export default newsSection;