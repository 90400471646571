/* eslint-disable no-underscore-dangle */
import React from 'react';
import Button from '../../molecules/Button/Button';

const ButtonGroup = ({ primary, darkBg, buttons }) => {
  const output = buttons.map((item, index) => {

    switch (item._type) {
      case 'buttonLinkEntry':
        return (
          <Button
            href={`/${item.entry.slug.current === "index" ? "" : item.entry.slug.current}`}
            key={item._key}
            textOnDarkBg={darkBg}
            primary={primary}
          >
            {item.buttonLabel}
          </Button>
        );
      case 'buttonLinkInternal':
        return (
          <Button
            href={item.uri}
            key={item._key}
            textOnDarkBg={darkBg}
            primary={primary}
          >
            {item.buttonLabel}

          </Button>
        );
      case 'buttonLinkExternal':
        return (
          <Button
            href={item.href}
            key={item._key}
            textOnDarkBg={darkBg}
            primary={primary}
          >
            {item.buttonLabel}

          </Button>
        );
      case 'buttonMedia':
        return (
          <Button
            href={item.file.asset.url}
            key={item._key}
            textOnDarkBg={darkBg}
            primary={primary}
          >
            {item.buttonLabel}

          </Button>
        );

      default:
        return (
          <Button
            to={item.href}
            key={item._key}
            textOnDarkBg={darkBg}
            primary={primary}
          >
            {item.buttonLabel}

          </Button>
        );
    }
  });

  return (
    <>
      {output}
    </>
  );
};

export default ButtonGroup;
