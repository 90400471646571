import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Container from '../../atoms/Container/Container';
import FancyHeading from '../../molecules/FancyHeading/FancyHeading';
import { OutboundLink } from "gatsby-plugin-google-gtag";

const StockistsSection = ({ block }) => {
  const { heading } = block;


  const data = useStaticQuery(
    graphql
    `
    query {
      stockistsOrder: allSanityStockistsOrder {
        nodes {
          stockistLists {
            heading
            stockistsOrder {
              href
              name
            }
          }
        }
      }
    }
    `,
  );

  return (
    <Container>
      <FancyHeading center="" darkBg="">{heading}</FancyHeading>

      {data.stockistsOrder.nodes[0].stockistLists.map((stockistList) => (
        <div className="mt-16">
          {stockistList.heading && <div className="font-lato text-lg mb-16">{stockistList.heading}</div>}
          {stockistList.stockistsOrder.map((stockist) => (
            <>
            <div className="mb-12 grid grid-rows-1 grid-cols-12">
              <div className="col-start-1 col-end-6 lg:col-end-4 xl:col-end-3">
                <div className="font-lato text-2xl mb-6">{stockist.name}</div>
                <div className={`w-32 h-px  bg-brand-lighter-grey`}  ></div>
              </div>
              <div className="col-start-7 lg:col-start-5 xl:col-start-4 col-end-12">
                <OutboundLink
                  href={stockist.href}
                  className="inline-flex items-center text-xs font-lato font-bold leading-3 px-12 py-4 border-2 border-black uppercase bg-transparent transition transition-duration-500 hover:bg-brand-red hover:border-brand-red hover:text-white"
                  target="blank"
                >
                  Buy Now
                </OutboundLink>
              </div>
            </div>
            </>
          ))}
        </div>
      ))}


      
    </Container>
  );
}

export default StockistsSection;