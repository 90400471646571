/* eslint-disable no-underscore-dangle */
// src/components/pageBuilder.js
import React from 'react';
import aboutSection from './pageBuilderElements/AboutSection/AboutSection';
import authorSection from './pageBuilderElements/AuthorSection/AuthorSection';
import videoSection from './pageBuilderElements/VideoSection/VideoSection';
import reviewsSection from './pageBuilderElements/ReviewsSection/ReviewsSection';
import mentoringSection from './pageBuilderElements/MentoringSection/MentoringSection';
import newsSection from './pageBuilderElements/NewsSection/NewsSection';
import stockistsSection from "./pageBuilderElements/StockistsSection/StockistsSection"

function PageBuilder(props) {
  const { type, pageBuilder, _rawPageBuilder } = props;
  // Load the right component, based on the _type from Sanity
  const Components = {
    aboutSection,
    authorSection,
    videoSection,
    reviewsSection,
    mentoringSection,
    newsSection,
    stockistsSection,
  };

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.items.map((block, index) => {

    return (
      Components[block._type] ? 
      React.createElement(Components[block._type], {
        key: block._key,
        block,
        type,
        raw: _rawPageBuilder.items[index],
      }) 
      :
      <></>
    );
  });
}
export default PageBuilder;
